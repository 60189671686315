
import { defineComponent, ref, reactive } from 'vue'
import { employeeHeadingsType, employeeDetailsType } from '@/types'
import { useRoute } from 'vue-router'
import CurrencyFormat from '@/filters/CurrencyFormat'
import Popup from '@/components/Popup.vue'
export default defineComponent({
  name: 'Home',
  emits: ['editEmployee', 'sortBy', 'setPage'],
  components: {
    Popup
  },
  props: {
    headings: {
      type: Array as () => employeeHeadingsType[],
      default: () => []
    },
    data: {
      type: [Array as () => employeeDetailsType[], String, Array]
    },
    type: {
      type: String,
      default: ''
    }
  },
  setup(props, { emit }) {
    const route = useRoute()
    const sortOrder = ref(route.query.order) || ref('asc')
    const isMissingComboPopup = ref(false)
    const listOfCombos = reactive({ value: [] })
    const getStatus = (status) => {
      let statusColor = 'grey'
      if (status == 'selector available') {
        statusColor = 'red'
      } else if (status == 'car ordered' || status == 'active lease') {
        statusColor = 'green'
      }
      return statusColor
    }
    const sortIcon = (value) => {
      let icon = 'default-icon '
      if (route.query.orderBy && route.query.order) {
        if (route.query.orderBy === value) {
          icon += route.query.order === 'asc' ? 'sort-asc' : 'sort-desc'
        }
      }
      return icon
    }
    const sortBy = (value) => {
      sortOrder.value = sortOrder.value === 'asc' ? 'desc' : 'asc'
      emit('sortBy', { orderBy: value, order: sortOrder.value })
    }
    const getFormatedDate = (value) => {
      return value.split('-').reverse().join('-')
    }
    const getNotFoundCombinations = (combinations) => {
      const notFoundCombos = combinations.map((item) => {
        const splitByUnderScore = item.split('_')
        splitByUnderScore[0] = `${splitByUnderScore[0]} months`
        splitByUnderScore[1] = `${splitByUnderScore[1]} km`
        item = splitByUnderScore.join(' / ')
        return item
      })
      return notFoundCombos
    }
    const openMissingCombo = (missingCombos) => {
      isMissingComboPopup.value = true
      listOfCombos.value = missingCombos
    }
    const closeMissingComboPopup = () => {
      isMissingComboPopup.value = false
    }
    return {
      getStatus,
      CurrencyFormat,
      sortIcon,
      sortOrder,
      sortBy,
      route,
      getFormatedDate,
      getNotFoundCombinations,
      isMissingComboPopup,
      closeMissingComboPopup,
      openMissingCombo,
      listOfCombos
    }
  }
})
